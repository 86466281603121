import { isStyleNode } from 'utils';

const STYLE_TARGET_SELECTOR = 'setel-container';

export function setup() {
  const target = document.body
    .appendChild(document.createElement(STYLE_TARGET_SELECTOR))
    .attachShadow({ mode: 'open' });
  styler.setStylesContainer(target);
  return target;
}

function findStyleContainer() {
  return document.querySelector(STYLE_TARGET_SELECTOR)?.shadowRoot;
}

export function injectStyles(linkTag: Node) {
  let linkNode = linkTag;
  const target = findStyleContainer();

  if (target) {
    const node = target.appendChild(linkNode);
    linkNode = node.cloneNode(true);
  }
}

interface Styler {
  _baseTarget: Node | null;
  setStylesContainer: (container: Node) => Styler;
  populateStyles: (...targets: Node[]) => Promise<undefined>;
}

export const styler: Styler = {
  _baseTarget: null,
  setStylesContainer(container: Node) {
    this._baseTarget = container;
    return this;
  },
  async populateStyles(...targets: Node[]) {
    if (!targets.length) return;
    if (!this._baseTarget) return;

    const stylesContainer = this._baseTarget;
    const hasStyleNodes = Array.from(stylesContainer.childNodes).some(
      isStyleNode
    );

    if (hasStyleNodes) {
      populate();
    } else {
      return new Promise((resolve) => {
        // if no style nodes found,
        // waits until styles/link tags injected, so can copy them accross
        // shadowRoot mount targets
        const observer = new MutationObserver((mutations, ob) => {
          const addedNodes = Array.from(mutations)
            .map((mut) => Array.from(mut.addedNodes))
            .flat();
          // check if style/link tag was added
          if (addedNodes.some(isStyleNode)) {
            ob.disconnect();
            populate();
            resolve(undefined);
          }
        });
        observer.observe(stylesContainer, { childList: true });
      });
    }

    function populate() {
      for (const target of targets) {
        const fragment = document.createDocumentFragment();
        for (const node of stylesContainer.childNodes) {
          if (isStyleNode(node)) {
            const cloned = node.cloneNode(true);
            fragment.appendChild(cloned);
          }
        }
        target.appendChild(fragment);
      }
    }
  },
};
