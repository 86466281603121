/* istanbul ignore file */

import { setup as setupWidgets, injectStyles } from './setup-widgets';

// assigns global so Webpack can use it in `cssInsert` runtime
window._setelWidgets = {
  injectStyles,
};

window.renderSetelWidgets = async function (opt) {
  setupWidgets();

  const { renderWidgets } = await import(
    /* webpackChunkName: "bootstrap" */
    './bootstrap'
  );
  await renderWidgets(opt);
};

if (process.env.NODE_ENV === 'development') {
  window.renderSetelWidgets();
}
